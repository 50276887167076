<template>
  <div class="mass-message-container">
    <!-- 返回区域 -->
    <div
      class="back-box"
      :class="{ 'bahasa-style': locale === 'bahasa-Indonesia' }"
      @click="backClick"
    >
      <i class="el-icon-arrow-left"></i>
      <span>{{ $t('GoBack') }}</span>
    </div>

    <!-- 标题区域 -->
    <div class="title-box">{{ $t('GroupMessage') }}</div>

    <!-- 表单区域 -->
    <div class="form-box">
      <el-form
        :model="form"
        :rules="formRules"
        ref="formRef"
        label-width="fit-content"
      >
        <!-- 标题 -->
        <el-form-item
          :label="$t('Title')"
          prop="title"
        >
          <el-input
            v-model="form.title"
            :placeholder="$t('InputTitle')"
            maxlength="150"
          ></el-input>
        </el-form-item>
        <!-- 内容 -->
        <el-form-item
          :label="$t('Content')"
          prop="content"
        >
          <el-input
            type="textarea"
            :rows="10"
            resize="none"
            v-model="form.content"
            :placeholder="$t('InputContent')"
            maxlength="1000"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!-- 图片上传、附件上传、选择分组 -->
        <div class="upload-group-box">
          <div class="upload-left">
            <!-- 图片 -->
            <el-form-item
              :label="$t('Picture')"
              prop="img"
              class="img-item-box"
            >
              <el-upload
                ref="imgRef"
                :action="uploadURL"
                accept=".jpeg,.jpg,.gif,.png"
                list-type="picture-card"
                :auto-upload="true"
                multiple
                :limit="6"
                :on-change="imgChange"
                :on-exceed="imgExceed"
              >
                <i
                  slot="default"
                  class="el-icon-plus"
                ></i>
                <div
                  slot="file"
                  slot-scope="{file}"
                >
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
            <!-- 附件 -->
            <el-form-item
              :label="$t('Attachments')"
              prop="attachment"
              class="upload-item-box"
            >
              <el-upload
                drag
                :action="uploadURL"
                accept=".rar,.zip,.doc,.docx,.pdf,.jpg,.xls,.xlsx,.png,.gif"
                :auto-upload="true"
                multiple
                :limit="3"
                :on-change="attachmentChange"
                :on-remove="attachmentRemove"
                :on-exceed="attachmentExceed"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">{{ $t('DragFile') }}<em>{{ $t('ClickUpload') }}</em></div>
                <div
                  class="el-upload__tip"
                  slot="tip"
                >{{ $t('Extension') }}: .rar .zip .doc .docx .pdf .jpg .xls .xlsx .png .gif</div>
              </el-upload>
            </el-form-item>
          </div>
          <div class="upload-right">
            <el-form-item
              label=""
              prop="groupIds"
            >
              <el-radio-group
                v-model="radioVal"
                @change="updateData"
              >
                <el-radio :label="0">{{ $t('SendPossessor') }}</el-radio>
                <el-radio :label="1">{{ $t('SendSpecificGroup') }}<i
                    class="el-icon-circle-plus-outline add-icon"
                    @click="showSelectGroup"
                  ></i></el-radio>
              </el-radio-group>
              <!-- 选择的分组 -->
              <ul
                v-if="radioVal === 1 && SelectGroupData.length"
                class="select-group"
              >
                <li
                  v-for="item in SelectGroupData"
                  :key="item.id"
                  class="item-li"
                >{{ item.groupName }}</li>
              </ul>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-box">
        <el-button
          :loading="sendLoading"
          @click="sendMessage"
        >{{ $t('Send') }}</el-button>
      </div>
    </div>

    <!-- 图片预览 -->
    <el-dialog
      :title="$t('PicturePreview')"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      class="picture-preview"
    >
      <img
        width="100%"
        :src="dialogImageUrl"
      />
    </el-dialog>

    <!-- 选择分组对话框 -->
    <SelectGroup
      :show.sync="selectGroup.show"
      :data="selectGroup"
      v-if="selectGroup.show"
    ></SelectGroup>
  </div>
</template>

<script>
import mixin from "@/assets/js/mixin";
import SelectGroup from "./dialog/SelectGroup.vue";
import api from "@/fetch/api";

export default {
  name: "MassMessage",
  mixins: [mixin],
  components: {
    SelectGroup,
  },
  data() {
    // 检查分组是否选中
    const checkGroup = (rule, value, callback) => {
      if (this.radioVal === 1) {
        if (!this.SelectGroupData.length)
          callback(new Error(this.$t("SelectGroupTip")));
        else callback();
      } else {
        callback();
      }
    };
    return {
      type: "send", // send:群发、edit:编辑并重新发送
      // 文件上传
      uploadURL:
        "https://test.attorneyathena.com/saas-api/athena-consumer/AppUploadDO/imgUpload",
      uploadImg: [], // 上传图片
      uploadAttachment: [], // 上传附件
      // 图片预览
      dialogVisible: false,
      dialogImageUrl: "",
      radioVal: 0,
      SelectGroupData: [], // 选中分组数据
      sendLoading: false,

      // 表单区域
      form: {
        title: "",
        content: "",
      },
      formRules: {
        title: [
          { required: true, message: this.$t("InputTitle"), trigger: "blur" },
        ],
        content: [
          { required: true, message: this.$t("InputContent"), trigger: "blur" },
        ],
        groupIds: [{ required: true, validator: checkGroup, trigger: "blur" }],
      },

      // 选择分区对话框
      selectGroup: {
        show: false,
        data: {},
      },
    };
  },
  created() {
    sessionStorage.removeItem("selectGroup");
    this.type = this.$route.query.type;
    if (this.type === "edit") this.dataEcho();
  },
  methods: {
    // 数据回显
    dataEcho() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      api
        .querySendMessageById({
          id: this.$route.query.id,
          language: languageObj[locale],
        })
        .then((res) => {
          const { title, content, isCheckAll, visibleList } = res.data;
          this.form = {
            title,
            content,
          };
          if (isCheckAll === 1) {
            this.radioVal = 0;
            this.SelectGroupData = [];
          } else if (isCheckAll === 0) {
            this.radioVal = 1;
            this.SelectGroupData = visibleList;
          }
        });
    },

    // 返回
    backClick() {
      sessionStorage.setItem("messageCurrentIndex", 0);
      this.$router.go(-1);
    },

    // 图片状态改变
    imgChange(file, fileList) {
      const arr = [];
      if (fileList.length) {
        fileList.map((item) => {
          if (item.status === "success") arr.push(item.response.data[0]);
        });
      }
      this.uploadImg = [...arr];
    },
    // 图片文件超出
    imgExceed() {
      this.$message.warning({ message: this.$t("PictureRestrict") });
    },
    // 图片预览
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 图片删除
    handleRemove(file) {
      this.$message.success({ message: this.$t("DeleteSuccess") });
      const arr = [];
      const fileList = this.$refs.imgRef.uploadFiles;
      // 删除
      if (fileList.length) {
        fileList.map((item, index) => {
          if (item.url === file.url) fileList.splice(index, 1);
        });
      }
      // 保存已上传图片
      if (fileList.length) {
        fileList.map((item) => {
          if (item.status === "success") arr.push(item.response.data[0]);
        });
      }
      this.uploadImg = [...arr];
    },

    // 附件状态改变
    attachmentChange(file, fileList) {
      const arr = [];
      if (fileList.length) {
        fileList.map((item) => {
          if (item.status === "success") arr.push(item.response.data[0]);
        });
      }
      this.uploadAttachment = [...arr];
    },
    // 附件删除
    attachmentRemove(file, fileList) {
      this.$message.success({ message: this.$t("DeleteSuccess") });
      const arr = [];
      if (fileList.length) {
        fileList.map((item) => {
          if (item.status === "success") arr.push(item.response.data[0]);
        });
      }
      this.uploadAttachment = [...arr];
    },
    // 附件文件超出
    attachmentExceed() {
      this.$message.warning({ message: this.$t("FileRestrict") });
    },

    // 显示选择分组对话框
    showSelectGroup() {
      this.selectGroup.show = true;
    },
    // 更新选中分组数据
    updateData(val) {
      if (val === 1) {
        this.SelectGroupData = sessionStorage.getItem("selectGroup")
          ? JSON.parse(sessionStorage.getItem("selectGroup"))
          : [];
      }
    },

    // 发送
    sendMessage() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.sendLoading = true;
          const { title, content } = this.form;
          const data = JSON.parse(
            localStorage.getItem("cacheChamber")
          ).adminData;
          const params = {
            chamberId: data.chamberId,
            adminId: data.id,
            createUserId: data.id,
            updateUserId: data.id,
            isCheckAll: 1, // 1:所有人、0:特定组
            messageType: 1, // 1:群发、2:私发
            title,
            content,
            imageList: "",
            attachmentList: "",
            groupIds: [],
          };
          if (this.uploadImg.length) {
            params.imageList = this.uploadImg.join(",");
          }
          if (this.uploadAttachment.length) {
            params.attachmentList = this.uploadAttachment.join(",");
          }
          if (this.radioVal === 1) {
            // 处理选中分组id
            const groupIds = [];
            if (this.SelectGroupData.length) {
              this.SelectGroupData.forEach((item) => {
                groupIds.push(item.groupId);
              });
            }
            params.groupIds = groupIds;
            params.isCheckAll = 0;
          }

          if (this.type === "send") {
            api.sendMessageNew(params).then(() => {
              sessionStorage.removeItem("selectGroup");
              this.$message.success({
                message: this.$t("SendSuccess"),
                duration: 2000,
              });
              this.$router.push({ name: "GroupMessage" });
              this.sendLoading = false;
            });
          } else {
            params.id = this.$route.query.id;
            api.updateMessageByMessageId(params).then(() => {
              sessionStorage.removeItem("selectGroup");
              this.$message.success({
                message: this.$t("SendSuccess"),
                duration: 2000,
              });
              this.$router.push({ name: "GroupMessage" });
              this.sendLoading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mass-message-container {
  width: 1600px;
  ul,
  li {
    list-style: none;
  }

  // 返回区域
  .back-box {
    width: 70px;
    text-align: center;
    background-color: #589ef8;
    padding: 5px 0;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
  }
  .bahasa-style {
    width: 95px;
  }

  // 标题区域
  .title-box {
    line-height: 50px;
    text-align: center;
    background-color: #425d7b;
    margin: 20px 0;
    color: white;
    font-size: 20px;
    border-radius: 3px;
  }

  // 表单区域
  .form-box {
    ::v-deep .el-form {
      .el-input__count {
        background-color: transparent;
        bottom: -10px;
        right: 20px;
      }

      // 图片上传、附件上传、选择分组
      .upload-group-box {
        display: flex;
        & > .upload-left {
          flex: 1;

          // 图片
          .img-item-box {
            .el-upload--picture-card {
              border: 1px dashed #939699;
              width: 80px;
              height: 80px;
              line-height: 80px;
            }
            .el-upload-list--picture-card .el-upload-list__item {
              width: 82px;
              height: 82px;
              & > div {
                height: 100%;
              }
            }
          }

          .el-upload {
            .el-upload-dragger {
              width: 240px;
              height: 130px;
              border: 1px solid #939699;
            }

            .el-icon-upload {
              margin: 15px 0 0;
            }

            .el-upload__text em {
              color: #695be0;
            }
          }
        }
        & > .upload-right {
          flex: 1;
          .el-radio-group {
            display: flex;
            flex-direction: column;
          }
          .el-radio {
            margin-right: 0;
            margin-bottom: 30px;
          }
          .el-radio:last-child {
            margin-bottom: 20px;
          }
          .add-icon {
            margin-left: 20px;
          }
          // 选择的分组
          .select-group {
            display: flex;
            flex-wrap: wrap;
            padding-left: 23px;
            padding-right: 100px;
            font-size: 14px;
            & > .item-li {
              margin-right: 15px;
            }
            & > .item-li:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .btn-box {
      text-align: center;
      .el-button {
        background-color: #589ef8;
        padding: 15px 50px;
        color: #ffffff;
        font-size: 18px;
      }
    }
  }
}
</style>
<style lang="scss">
.picture-preview {
  .el-dialog {
    min-width: 600px;
    width: 600px;
  }
}
</style>